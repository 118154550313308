import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, switchMap, take } from 'rxjs';

import { IsApiUrl } from '../services/auth.util';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectJwtToken } from '../store/authorization.selectors';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(private store: Store, private router: Router) { }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return !IsApiUrl(request)
      ? next.handle(request)
      : this.store.select(selectJwtToken).pipe(
        take(1),
        map(jwtToken => {
          return !jwtToken
            ? request
            : request.clone({
              setHeaders: {
                Authorization: `Bearer ${jwtToken}`,
              },
            })
        }
        ),
        switchMap(req => next.handle(req)),

      );
  }
}
