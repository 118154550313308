import {
  filter,
  of,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import {
  skipCacheRequest,
} from './configurations.actions';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { EXPIRE_API_SECONDS } from '../../shared/constants/api-priority.seconds';
import { Store } from '@ngrx/store';


@Injectable()
export class ConfigurationsEffects {
  constructor(
    private _actions: Actions,
    private toast: ToastrService,
    private translate: TranslateService,
    private store: Store
  ) { }


  skipCacheChange$ = createEffect(
    () =>
      this._actions.pipe(
        ofType(skipCacheRequest),
        filter(value => value.skipCache),
        switchMap((data) =>
          data.showToaster ? this.translate
            .get('general.skip_cache_enabled', {
              interval: EXPIRE_API_SECONDS.SKIP_CACHE / 60,
              interval_name: 'min',
            }) : of(false)
              .pipe(take(1))
        ),
        take(1),
        filter(message => !!message),
        tap(message => this.toast.info(message))
      ),
    { dispatch: false }
  );










  // cacheLocation$ = createEffect(() =>
  //   this._actions.pipe(
  //     ofType(CacheLocation.byNo),
  //     distinctUntilChanged(),
  //     withLatestFrom(this._store.select(selectLocations(moment()))),
  //     mergeMap(([{ locationNo }, locations]) => {
  //       const location = locations?.find(l => l?.no === locationNo);
  //       return location
  //         ? of(CacheLocation.byNoSuccess({ location: location }))
  //         : this.clientLocation
  //           .getLocationById( locationNo)
  //           .pipe(
  //             nswagCatchOperator(),
  //             map(response =>
  //               response.succeeded && !!response.data
  //                 ? CacheLocation.byNoSuccess({
  //                   location: response.data,
  //                 })
  //                 : CacheLocation.failure()
  //             )
  //           );
  //     })
  //   )
  // );



}
