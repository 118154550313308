import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SharedService } from 'src/app/shared/services/shared.service';


@Injectable()
export class TenantInterceptor implements HttpInterceptor {
  constructor(
    private sharedService: SharedService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // ky header duhet derguar vetem tek kjo thirrje.
    // TODO te shikohet mundesia te kalohet si paramater sepse ky interceptor perdoret vetem per kete thirrje.
    // Ne front nuk behet ne menyre tjeter sepse thirrja eshte nga nswag dhe nuk nderhyjme dot tek request pervecse me interceptor
    if (request.url.includes('api/User/user/all')) {
      const encodedTenantId = this.sharedService.getTenantId();
      const decodedContractId = atob(encodedTenantId);
      request = request.clone({
        setHeaders: {
          'X-Tenant-Id': decodedContractId,
        },
      });
      return next.handle(request);
    }
    return next.handle(request);
  }
}
