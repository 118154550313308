export const ApiPrioritySeconds = {
  lesser: 3600,
  lower: 600,
  low: 120,
  medium: 60,
  high: 30,
  critical: 15,
  once: 1,
};

export const EXPIRE_API_SECONDS = {
  COUNTRIES: ApiPrioritySeconds.high,
  SKIP_CACHE: ApiPrioritySeconds.low,
  CURRENCIES: ApiPrioritySeconds.critical,
  COSTINGCENTER: ApiPrioritySeconds.lower,
  API_VERSION: ApiPrioritySeconds.lesser,
  USERS: ApiPrioritySeconds.lesser,
  UNIT_OF_MEASURES: ApiPrioritySeconds.lesser,
  LOCATIONS: ApiPrioritySeconds.medium,
};
