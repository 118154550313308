import { IStateData } from 'src/app/shared/interfaces/state/state';


export const usersFeatureKey = 'users';

export const usersPersistKeys = ['users'].map(
  value => `${usersFeatureKey}.${value}`
);

export interface UsersState {
  test: string | undefined;
  // roles: RoleDto[];
  // countries?: IStateData<CountryDto[]>;
  // roleResult: PagedResultOfRoleDto;
  loading: boolean;
  success?: boolean;
}

export const initialUsersState: UsersState = {
  test: undefined,
  // roles: [],
  // countries: {
  //   data: undefined,
  //   lastUpdatedDate: undefined,
  //   priority: 0,
  // },
  // roleResult: new PagedResultOfRoleDto(),
  loading: false,
  success: undefined,
};
