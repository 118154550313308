import { createAction, props } from '@ngrx/store';
import { User } from './configurations.state';

export const activeCurrenciesRequest = createAction(
  '[Set] activeCurrencies request'
);
export const activeCostingCentersRequest = createAction(
  '[Set] activeCostingCenters request'
);
export const onAddCurrency = createAction('[activeCurrencies] AddCurrency');
export const onDeleteCurrency = createAction(
  '[activeCurrencies] DeleteCurrency'
);

export const activeCurrenciesFailure = createAction(
  '[Set] activeCurrencies fail',
  props<{
    error: string;
  }>()
);

export const activeCostingCentersFailure = createAction(
  '[Set] CostingCenters fail',
  props<{
    error: string;
  }>()
);
export const skipCacheRequest = createAction(
  '[Set] Skip Cache Change',
  props<{ skipCache: boolean, showToaster: boolean }>()
);

export const apiVersionRequest = createAction('[Set] Api Version request');

export const apiVersionSuccess = createAction(
  '[Set] Api Version success',
  props<{ apiVersion?: string }>()
);
export const apiVersionFailure = createAction(
  '[Set] Api Version request fail',
  props<{
    error: string;
  }>()
);

export const cacheUser = {
  ById: createAction('[Get User] Cache User By Id', props<{ id: string }>()),
  ByIdSuccess: createAction(
    '[Get User] Cache User By Id Success',
    props<{ user: User }>()
  ),
  ByIdFailure: createAction('[Get User] Cache User By Id Failure'),
};

export const CacheCostingCenter = {
  failure: createAction('[costingCenter] Cache Failure'),
  skip: createAction('[costingCenter] Cache Skip'),
  byId: createAction('[costingCenter] Cache By ID', props<{ costingCenterId: number }>()),
}


//#endregion </editor-fold>
