import { createReducer, on } from '@ngrx/store';
import {
  authLogout,
  authLoginRequest,
  authLoginSuccess,
  authLoginFailure,

} from './authorization.actions';
import { initialAuthorizationState } from './authorization.state';

// reduceri
export const authorizationReducer = createReducer(
  initialAuthorizationState,

  //#region <editor-fold desc="Login Step1">
  on(authLoginRequest, state => ({
    ...state,
    loading: true,
  })),
  on(authLoginSuccess, (state, { loggedUserName, token }) => ({
    ...state,
    successLogin: true,
    loading: false,
    loggedUserName: loggedUserName,
    token: token
  })),
  //#endregion </editor-fold>
  on(authLoginFailure, (state, { error }) => ({
    ...state,
    error: error,
    loading: false
  })),

  //#region <editor-fold desc="Logout">
  on(authLogout, state => ({
    ...state,
    token: undefined,
    companyDto: undefined,
    loading: false,
    companyList: undefined,
    loggedUserName: undefined
  })),
  //#endregion </editor-fold>
);
