import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    configurationsFeatureKey,
    ConfigurationsState,
} from './configurations.state';
import { Moment } from 'moment';
import { EXPIRE_API_SECONDS } from '../../shared/constants/api-priority.seconds';
import { GetStateData } from '../../shared/interfaces/state/state';

export const selectFeature = createFeatureSelector<ConfigurationsState>(
    configurationsFeatureKey
);

export const selectSkipCache = (now: Moment) =>
    createSelector(selectFeature, (state: ConfigurationsState) =>
        GetStateData(state.skipCache, now, EXPIRE_API_SECONDS.SKIP_CACHE, false)
    );

// export const selectActiveCurrencies = (now: Moment) =>
//     createSelector(selectFeature, selectSkipCache(now), (state, skipCache) =>
//         GetStateData(state.activeCurrencies, now, EXPIRE_API_SECONDS.CURRENCIES, skipCache)
//     );

// export const selectActiveCostingCenter = (costingCenterId: number, now: Moment) =>
//     createSelector(selectFeature, selectSkipCache(now), (state, skipCache) => {
//         const costingC = state.cachedCostingCenters.find(
//             value => value.data?.id === costingCenterId
//         );
//         return costingC
//             ? GetStateData(costingC, now, EXPIRE_API_SECONDS.COSTINGCENTER, skipCache)
//             : undefined;
//     });

// export const selectActiveCostingCenters = (now: Moment) =>
//     createSelector(selectFeature, selectSkipCache(now), (state, skipCache) => {
//         return state.cachedCostingCenters.map(cos =>
//             GetStateData(cos, now, EXPIRE_API_SECONDS.COSTINGCENTER, skipCache)

//         )
//     }
//     );




export const selectApiVersion = (now: Moment) =>
    createSelector(selectFeature, selectSkipCache(now), (state, skipCache) =>
        GetStateData(state.apiVersion, now, EXPIRE_API_SECONDS.API_VERSION, skipCache)
    );

export const selectUsers = (now: Moment) =>
    createSelector(selectFeature, selectSkipCache(now), (state, skipCache) =>
        state.cachedUsers.map(user =>
            GetStateData(user, now, EXPIRE_API_SECONDS.USERS, skipCache)
        ));
export const selectUser = (id: string, now: Moment) =>
    createSelector(selectFeature, selectSkipCache(now), (state, skipCache) => {
        const user = state.cachedUsers.find(
            value => value.data?.key === id
        );
        return user
            ? GetStateData(user, now, EXPIRE_API_SECONDS.USERS, skipCache)
            : undefined;
    });


// export const selectCostingC = (now: Moment) =>
//     createSelector(selectFeature, selectSkipCache(now), (state, skipCache) => {
//         const cs = state.cachedCostingCenters.map(cos =>
//             GetStateData(cos, now, EXPIRE_API_SECONDS.COSTINGCENTER, skipCache)
//         ).filter(el => !!el)
//         return cs.length === 0 ? undefined : cs;
//     }
//     );