import { CompanyDto } from "src/app/shared/nswag.api";

export const authorizationFeatureKey = 'authorization';

export const authorizationPersistKeys = [
  'token',
  'loggedUserName',
  'companyDto',
].map(value => `${authorizationFeatureKey}.${value}`);

export interface AuthorizationState {
  token: string | undefined;
  loggedUserName: string | undefined;
  loading: boolean,
  companyDto?: CompanyDto;
}

export const initialAuthorizationState: AuthorizationState = {
  token: undefined,
  loading: false,
  loggedUserName: undefined,
  companyDto: undefined,
};
