/* eslint-disable @typescript-eslint/no-unused-vars,unused-imports/no-unused-vars */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, take, tap } from 'rxjs';
import { Store } from '@ngrx/store';
import { AuthorizationState } from '../store/authorization.state';
import { isUserTokenPresent } from '../store/authorization.selectors';

@Injectable({
  providedIn: 'root',
})
export class LoggedInGuard  {
  constructor(
    private store: Store<AuthorizationState>,
    private router: Router
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.isLoggedIn;
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.isLoggedIn;
  }

  private get isLoggedIn() {
    return this.store.select(isUserTokenPresent).pipe(
      take(1),
      tap(isLoggedIn => {
        if (!isLoggedIn) {
          // console.log('Not logged in');
          this.router.navigate(['/auth/login']);
        }
      })
    );
  }
}
