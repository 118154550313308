import { Injectable, NgModule } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, tap } from 'rxjs/operators';
import {
  authLoginFailure,
  authLoginRequest,
  authLoginSuccess,
  authLogout,
} from './authorization.actions';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { nswagCatchOperator } from 'src/app/shared/operators/nswag-catch-operator';
import { HttpClientAuthentication, LoginRequest } from 'src/app/shared/nswag.api';

@Injectable()
export class AuthorizationEffects {
  constructor(
    private store: Store,
    private _actions: Actions,
    private router: Router,
    private httpClientAuthentication: HttpClientAuthentication
  ) { }

  @NgModule({
    providers: [HttpClientAuthentication],
  })


  loginRequest$ = createEffect(() =>
    this._actions.pipe(
      ofType(authLoginRequest),
      switchMap(({ username, password }) =>
        this.httpClientAuthentication
          .login(
            new LoginRequest({
              username: username,
              password: password,
            })
          )
          .pipe(
            nswagCatchOperator(),
            map(response => {
              if (response.succeeded && !!response.data) {
                return authLoginSuccess({
                  loggedUserName: response.data.name ?? '',
                  token: response.data.token ?? ''
                });
              } else {
                return authLoginFailure({
                  error: response.message ?? 'Unknown error',
                });
              }
            })
          )
      )
    )
  );

  logout$ = createEffect(
    () =>
      this._actions.pipe(
        ofType(authLogout),
        tap(() => {
          this.router.navigate(['/auth/login']);
        })
      ),
    { dispatch: false }
  );


}
