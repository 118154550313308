import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  authorizationFeatureKey,
  AuthorizationState,
} from './authorization.state';

export const selectFeature = createFeatureSelector<AuthorizationState>(
  authorizationFeatureKey
);

export const isUserTokenPresent = createSelector(
  selectFeature,
  (state: AuthorizationState) => !!state.token
);

export const isAuthorizationLoading = createSelector(
  selectFeature,
  (state: AuthorizationState) => state.loading
);

export const selectJwtToken = createSelector(
  selectFeature,
  (state: AuthorizationState) => state?.token
);

export const selectLoggedUserName = createSelector(
  selectFeature,
  (state: AuthorizationState) => state.loggedUserName
);

