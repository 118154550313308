import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  assetsUrl: string;
  activeFiltersNumber: Subject<number> = new Subject<number>();
  encodedTenant!: string;

  protected jsonParseReviver: ((key: string, value: any) => any) | undefined =
    undefined;

  constructor(
    private router: Router,
    private toast: ToastrService,
  ) {
    this.assetsUrl = environment.assetsUrl;
  }

  creditValue = new Subject<any>();

  getValue(): Observable<any> {
    return this.creditValue.asObservable();
  }
  sendValue(val: any) {
    this.creditValue.next(val);
  }
  getTenantId() {
    return this.encodedTenant;
  }
  setTenantId(encodedString: string) {
    this.encodedTenant = encodedString;
  }

  // initAmountDto(
  //   exch: number | null | undefined,
  //   currency: CurrencyEnum | null,
  //   amount: number | null | undefined,
  //   valuesAreInLocalCurrency?: boolean
  // ): AmountDto {
  //   if (!valuesAreInLocalCurrency) {
  //     return new AmountDto({
  //       amountCCY: amount!,
  //       currency: currency ?? CurrencyEnum.ALL,
  //       exchangeRate: exch ?? 1,
  //       amountLCY: amount! * exch!,
  //     });
  //   } else {
  //     return new AmountDto({
  //       amountCCY: amount! / exch!,
  //       currency: currency ?? CurrencyEnum.ALL,
  //       exchangeRate: exch ?? 1,
  //       amountLCY: amount!,
  //     });
  //   }
  // }

  openUrlNewWindow(
    path: string,
    isViewOnlyMode: boolean = true,
    width = window.screen.width * 0.95,
    height = window.screen.height * 0.9
  ) {
    const top =
      window.screen.height > height ? (window.screen.height - height) / 2 : 10;
    const left =
      window.screen.width > width ? (window.screen.width - width) / 2 : 10;
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`${path}`], {
        queryParams: !isViewOnlyMode ? undefined : { prevent_click: true }
      })
    );
    window.open(
      url,
      undefined,
      `height=${height},width=${width},popup=yes,top=${top},left=${left},noopener=yes,location=no,menubar=no,toolbar=no,status=no,titlebar=no`
    );
  }

  // downloadBlob(response: FileResponse, name: string | undefined = undefined) {
  //   const url = window.URL.createObjectURL(response.data);
  //   const anchor = document.createElement('a');
  //   anchor.download =
  //     name === undefined ? 'file' + this.parseBlobToExtension(response.data)
  //       : name + this.parseBlobToExtension(response.data);
  //   anchor.href = url;
  //   anchor.click();
  // }

  private parseBlobToExtension(blob: Blob): string {
    const mimeType = blob.type;
    const extensionMap: { [key: string]: string } = {
      'image/jpeg': '.jpg',
      'image/png': '.png',
      'image/gif': '.gif',
      'image/tiff': '.tiff',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        '.xlsx',
      'application/pdf': '.pdf',
      // Add more MIME types and extensions as needed
    };

    const extension = extensionMap[mimeType] || '';
    console.log('Extension:', extension);
    return extension;
  }

  decodeBase64(base64String: string) {
    const jsonString = Buffer.from(base64String, 'base64').toString();
    return JSON.parse(jsonString);
  }
  // documentClick(
  //   orderType: RelatedEntityEnum | undefined,
  //   orderNo: string | undefined
  // ) {
  //   let link: string = '';
  //   switch (orderType) {
  //     case RelatedEntityEnum.PurchaseRequestHeaderEntity:
  //       link = `/purchases/purchase-request/view/${orderNo}`;
  //       break;
  //     case RelatedEntityEnum.InventoryAdjustmentHeaderEntity:
  //       link = `/inventory/inventory-adjustment/view/${orderNo}`;
  //       break;
  //     case RelatedEntityEnum.SalesInvoiceHeaderEntity:
  //       link = `/sales/sale-invoice/view/${orderNo}`;
  //       break;
  //     case RelatedEntityEnum.TransferHeaderEntity:
  //       link = `/inventory/transfers/view/${orderNo}`;
  //       break;
  //     case RelatedEntityEnum.PayrollHeaderEntity:
  //       link = `/human-resources/payroll/view/${orderNo}`;
  //       break;
  //     case RelatedEntityEnum.GeneralJournalHeaderEntity:
  //       link = `/financial-management/general-journal/view/${orderNo}`;
  //       break;
  //     case RelatedEntityEnum.TransferReceiptHeaderEntity:
  //       link = `/inventory/transferreceipts/view/${orderNo}`;
  //       break;
  //     case RelatedEntityEnum.TransferShipmentHeaderEntity:
  //       link = `/inventory/transfershipments/view/${orderNo}`;
  //       break;

  //     case RelatedEntityEnum.ShippingAgentEntity:
  //       link = `/inventory/shipping-agent`;
  //       break;
  //     case RelatedEntityEnum.VehicleEntity:
  //       link = `/inventory/vehicle`;
  //       break;

  //     case RelatedEntityEnum.BankDepositEntity:
  //       link = `/financial-management/bankdeposit/view/${orderNo}`;
  //       break;
  //     case RelatedEntityEnum.BankWithdrawEntity:
  //       link = `/financial-management/bankwithdraw/view/${orderNo}`;
  //       break;
  //     case RelatedEntityEnum.CashReceiptEntity:
  //       link = `/financial-management/cashreceipts/view/${orderNo}`;
  //       break;
  //     case RelatedEntityEnum.CashPaymentEntity:
  //       link = `/financial-management/cashpayments/view/${orderNo}`;
  //       break;
  //     case RelatedEntityEnum.ItemEntity:
  //       link = `/items/view/${orderNo}`;
  //       break;
  //     case RelatedEntityEnum.SalesRequestHeaderEntity:
  //       link = `/sales/sale-request/view/${orderNo}`;
  //       break;
  //     case RelatedEntityEnum.PurchaseInvoiceHeaderEntity:
  //       link = `/purchases/purchase-invoice/view/${orderNo}`;
  //       break;

  //     // todo catch all routes
  //     default:
  //       this.toast.error(
  //         `Nuk eshte implementuar route per tipin me id ${orderType}. Kontaktoni supportin per me shume.`
  //       );
  //       break;
  //   }
  //   if (link !== '') this.openUrlNewWindow(link);
  // }
}
