import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngrx/store';
import { authLogout } from './app/authorization/store/authorization.actions';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private toast: ToastrService,
    private store: Store
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        if (request.headers.has('SKIP_TOAST')) return throwError(() => err);
        const language =
          JSON.parse(localStorage.getItem(`__${'root'}`)!)?.dashboard
            ?.language ?? 'sq';
        const error = this.blobToString(err?.error);
        switch (err?.status) {
          case 401:
            // this.toast.error(
            //   language === 'en' ? 'Sesion expired' : 'Sesioni ka skaduar'
            // );
            this.store.dispatch(authLogout());
            break;
          case 403:
            this.toast.error(
              error.message
                ? error.message
                : language === 'en'
                  ? "You don't have permission to access this page"
                  : 'Ju nuk keni akses ne kete faqe'
            );
            this.router.navigate(['/no-permission']);
            break;
          case 400:
          case 500:
            this.toast.error(
              error.message
                ? error.message
                : language === 'en'
                  ? 'Server error'
                  : 'Gabim në server'
            );
            break;
        }
        return throwError(() => err);
      })
    );
  }

  blobToString(b: any) {
    try {
      let u, x;
      u = URL.createObjectURL(b);
      x = new XMLHttpRequest();
      x.open('GET', u, false); // although sync, you're not fetching over internet
      x.send();
      URL.revokeObjectURL(u);
      return JSON.parse(x.responseText);
    } catch {
      return null;
    }
  }
}
