import { Action, ActionReducer, INIT, UPDATE } from '@ngrx/store';
import { merge, pick } from 'lodash';
import { environment } from '../../environments/environment';

export function storageMetaReducer<S, A extends Action = Action>(
  saveKeys: string[],
  localStorageKey: string
) {
  return function (reducer: ActionReducer<S, A>) {
    return function (state: S, action: A): S {
      const nextState = reducer(state, action);
      // init the application state.
      if (action.type === INIT || action.type === UPDATE) {
        const storageValue = localStorage.getItem(`__${localStorageKey}`);
        if (storageValue) {
          try {
            const savedState = JSON.parse(storageValue);
            return merge(nextState, savedState);
          } catch {
            localStorage.removeItem(`__${localStorageKey}`);
          }
        }
      }

      // save the next state to the application storage.
      const stateToSave = pick(nextState, saveKeys);
      localStorage.setItem(`__${localStorageKey}`, JSON.stringify(stateToSave));
      return nextState;
    };
  };
}

export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {
    const result = reducer(state, action);
    if (environment.production) return result;

    // console.groupCollapsed(action.type);
    // console.log('prev state', state);
    // console.log('action', action);
    // console.log('next state', result);
    // console.groupEnd();
    return result;
  };
}
