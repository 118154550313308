export const environment = {
  //TODO change signalRUrl
  signalRUrl: 'https://localhost:7095',
  production: true,
  baseUrl: `${window.location.protocol}//${window.location.hostname}${window.location.port ? ':' + window.location.port : ''
    }`,
  assetsUrl: `${window.location.protocol}//${window.location.hostname}${window.location.port ? ':' + window.location.port : ''
    }`,
  google: {
    key: '1027429328341-7ohenpbd3eqv20q0k3utfu8dt6e82efe.apps.googleusercontent.com',
  }
};
