import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment.prod';
// import * as Sentry from '@sentry/angular-ivy';

if (environment.production) {
  enableProdMode();

  // Sentry.init({
  //   dsn: environment.sentry.dsn,
  //   tracePropagationTargets: environment.sentry.allowed_sites,
  //   integrations: [
  //     new Sentry.Replay({
  //       maskAllText: true,
  //       blockAllMedia: true,
  //     }),
  //   ],
  //   replaysSessionSampleRate: 0.1,
  //   replaysOnErrorSampleRate: 1.0,
  // });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(() => console.log('Bootstrap success'))
  .catch(err => console.error(err));
