import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil, tap } from 'rxjs';
import { selectLanguage } from './dashboard/store/dashboard.selectors';
import { NavigationService } from './shared/services/navigation-service.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnDestroy, OnInit {
  lang = 'sq';

  unsubscribe$ = new Subject<boolean>();

  constructor(
    private router: Router,
    private translate: TranslateService,
    private store: Store,
    private navigationService: NavigationService,
  ) {
    navigationService.startSaveHistory();
  }

  ngOnInit() {
    this.getLanguage$.subscribe();
  }
  private get getLanguage$() {
    return this.store.select(selectLanguage).pipe(
      takeUntil(this.unsubscribe$),
      tap(language => this.translate.use(language ?? 'sq'))
    );
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
