import { EXPIRE_API_SECONDS } from 'src/app/shared/constants/api-priority.seconds';
import { IStateData } from 'src/app/shared/interfaces/state/state';
import moment from 'moment';

export const configurationsFeatureKey = 'configurations';

export const configurationsPersistKeys = [
  'costingCenter',
  'skipCache',
  'cachedUsers'
].map(value => `${configurationsFeatureKey}.${value}`);

export interface User {
  key: string,
  value: string
}

export interface ConfigurationsState {
  // activeCurrencies?: IStateData<CurrencyDto[] | undefined>;
  skipCache: IStateData<boolean>;
  apiVersion: IStateData<string> | undefined;
  cachedUsers: IStateData<User>[]
  // cachedCostingCenters: IStateData<CostingCenterDto>[]
}

export const initialConfigurationsState: ConfigurationsState = {
  // activeCurrencies: {
  //   data: undefined,
  //   lastUpdatedDate: undefined,
  //   priority: EXPIRE_API_SECONDS.CURRENCIES,
  // },
  skipCache: {
    data: false,
    lastUpdatedDate: moment(),
    priority: EXPIRE_API_SECONDS.SKIP_CACHE,
  },
  apiVersion: undefined,
  cachedUsers: [],
  // cachedCostingCenters: []
};
