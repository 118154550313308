import { createReducer, on } from '@ngrx/store';
import {
    CacheCostingCenter,
    activeCostingCentersRequest,
    activeCurrenciesFailure,
    activeCurrenciesRequest,
    apiVersionFailure,
    apiVersionRequest,
    apiVersionSuccess, cacheUser,
    onAddCurrency,
    onDeleteCurrency,
    skipCacheRequest,
} from './configurations.actions';
import { initialConfigurationsState } from './configurations.state';
import * as moment from 'moment';
import { EXPIRE_API_SECONDS } from 'src/app/shared/constants/api-priority.seconds';
import { IStateData } from 'src/app/shared/interfaces/state/state';

// reduceri
export const configurationsReducer = createReducer(
    initialConfigurationsState,

    on(activeCurrenciesRequest, state => ({
        ...state,
        activeCurrencies: undefined,
    })),
    on(onAddCurrency, state => ({
        ...state,
        activeCurrencies: undefined,
    })),
    // on(activeCurrenciesSuccess, (state, { activeCurrencies }) => ({
    //     ...state,
    //     activeCurrencies: {
    //         data: activeCurrencies,
    //         lastUpdatedDate: moment(),
    //         priority: EXPIRE_API_SECONDS.CURRENCIES,
    //     },
    // })),
    on(activeCurrenciesFailure, state => ({
        ...state,
    })),
    on(onDeleteCurrency, state => ({
        ...state,
        activeCurrencies: undefined,
    })),
    on(skipCacheRequest, (state, { skipCache }) => ({
        ...state,
        skipCache: {
            data: skipCache,
            lastUpdatedDate: moment(),
            priority: EXPIRE_API_SECONDS.SKIP_CACHE,
        },
    })),

    on(apiVersionRequest, state => ({
        ...state,
        apiVersion: undefined,
    })),
    on(apiVersionSuccess, (state, { apiVersion }) => ({
        ...state,
        apiVersion: {
            data: apiVersion,
            lastUpdatedDate: moment(),
            priority: EXPIRE_API_SECONDS.API_VERSION,
        },
    })),
    on(apiVersionFailure, state => ({
        ...state,
    })),

    on(cacheUser.ByIdSuccess, (state, { user }) => {
        return {
            ...state,
            cachedUsers: [
                ...state.cachedUsers.filter(u => u.data?.key !== user.key),
                ...[{
                    data: user,
                    lastUpdatedDate: moment(),
                    priority: EXPIRE_API_SECONDS.USERS
                }]
            ]
        }
    }),
    on(activeCostingCentersRequest, state => ({
        ...state,
        cachedCostingCenters: [],
    })),
    // on(activeCostingCentersSuccess, (state, { costingCenter }) => {
    //     return {
    //         ...state,
    //         cachedCostingCenters: costingCenter?.map((el) => {
    //             let a = state.cachedCostingCenters;
    //             return <IStateData<CostingCenterDto>>{
    //                 data: el,
    //                 lastUpdatedDate: moment(),
    //                 priority: EXPIRE_API_SECONDS.COSTINGCENTER
    //             }
    //         }) ?? []
    //     }
    // }),

    // on(CacheCostingCenter.byIdSuccess, (state, { costingCenterDto }) => {
    //     return {
    //         ...state,
    //         cachedCostingCenters: [
    //             ...state.cachedCostingCenters.filter(c => c.data?.id !== costingCenterDto.id),
    //             ...[{
    //                 data: costingCenterDto,
    //                 lastUpdatedDate: moment(),
    //                 priority: EXPIRE_API_SECONDS.COSTINGCENTER
    //             }]
    //         ]
    //     }
    // })
);
