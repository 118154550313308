import { HttpRequest } from '@angular/common/http';
import { environment } from '../../../environments/environment';

export interface LastUserInStorage {
  username: string;
}

export function GetLastLoggedInUser(): LastUserInStorage | undefined {
  const obj = localStorage.getItem('lastLoggedInUser');
  if (!obj) return undefined;

  try {
    const lastUser = JSON.parse(obj);
    if (!lastUser) return undefined;
    if (lastUser.username) {
      // console.debug('Last logged in user received from storage', lastUser);
      return lastUser;
    }
    return undefined;
  } catch (error) {
    // console.warn('No last logged in user could be parsed');

    return undefined;
  }
}

export function SetLastLoggedInUser(
  username: string | null
): void {
  const lastUser: LastUserInStorage = {
    username: username ?? '',
  };
  localStorage.setItem('lastLoggedInUser', JSON.stringify(lastUser));
  // console.debug('Last logged in user saved in storage', lastUser);
}

export function ClearLastLoggedInUser(): void {
  localStorage.removeItem('lastLoggedInUser');
  // console.debug('Last logged in user removed from storage');
}

/**
 * Checks if the request is for the API url.
 * @param req
 */
export function IsApiUrl(req: HttpRequest<any>) {
  // It's an absolute url with the same origin.
  if (req.url.startsWith(`${environment.baseUrl}/`)) {
    return true;
  }

  // It's a relative url like /api/Products
  // eslint-disable-next-line no-useless-escape
  if (/^\/[^\/].*/.test(req.url)) {
    return true;
  }

  // It's an absolute or protocol relative url that
  // doesn't have the same origin.
  return false;
}
