import { createAction, props } from '@ngrx/store';
//#region <editor-fold desc="Login Step1">
export const authLoginRequest = createAction(
  '[Authorization] Login  Request',
  props<{
    username: string;
    password: string;
  }>()
);
export const authLoginSuccess = createAction(
  '[Authorization] Login  Success',
  props<{
    loggedUserName: string;
    token: string
  }>()
);

export const authLoginFailure = createAction(
  '[Authorization] Login  Failure',
  props<{
    error: string;
  }>()
);
//#endregion </editor-fold>

//#region <editor-fold desc="Login Step1 Social">



//#endregion </editor-fold>

//#region <editor-fold desc="Login Step2">

export const authLoginStep2Failure = createAction(
  '[Authorization] Login Step2 Failure',
  props<{
    error: string;
  }>()
);
//#endregion </editor-fold>

//#region Logout <editor-fold desc="Logout">

export const authLogout = createAction('[Authorization] Logout');

//#endregion </editor-fold>
